footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #eff1f3;
  background-color: #12355b;
  flex-shrink: 0;
  padding-top: 1rem;
  width: 100%;
}

footer > img {
  width: 12rem;
  margin-bottom: 1rem;
}

.footer-socials {
  display: flex;
  flex-direction: row;
  margin: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-socials > li {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-socials > li > a {
  display: flex;
  text-decoration: none;
  color: #eff1f3;
  align-items: center;
  margin-left: 1rem;
}

.footer-socials > li :hover {
  color: #d1ac00;
  transition: ease-in-out 0.2s;
}

.footer-socials > li > a > img {
  width: 1.75rem;
  margin-right: 0.5rem;
}
