.base-degrees-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.extra-degrees-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.extra-degrees-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.extra-degrees-row-container .degree-container {
  margin-left: 2rem;
  margin-right: 2rem;
}

.degree-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 33%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.degree-container img {
  mask-image: none !important;
}

.degree-container .degree-desc {
  font-size: 3vw;
  margin-top: 0.5rem;
  padding: 0;
  max-width: 100% !important;
}

.degree-container .degree-title {
  padding: 0;
  font-size: 3.5vw;
  max-width: 100% !important;
}

@media screen and (min-width: 1200px) {
  .base-degrees-container {
    width: 65%;
  }

  .degree-container {
    max-width: 20%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }

  .degree-container .degree-desc {
    font-size: 1vw;
    margin-top: 1vw;
  }

  .degree-container .degree-title {
    font-size: 1.5vw;
    text-transform: uppercase;
  }

  .extra-degrees-row-container .degree-container img {
    max-height: 250px !important;
    width: auto !important;
  }

  .ahtaaja img {
    max-height: 250px !important;
    width: auto !important;
  }
}
