.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 0 auto;
}

.app-container .afterparty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: black;
  color: white;
  width: 100%;
}

.app-container h1 {
  padding-top: 1rem;
  text-transform: uppercase;
  max-width: 100%;
  font-size: 5.25vw;
  text-align: center;
  color: #12355b;
  /*
  -webkit-text-stroke-width: 0.25px;
  -webkit-text-stroke-color: black;
  */
}

.app-container h2 {
  margin: 1rem 5rem 1rem 5rem;
  text-transform: uppercase;
  max-width: 100%;
  font-size: 4.5vw;
}

.app-container p {
  padding: 1rem 1rem 1rem 1rem;
  max-width: 100%;
  font-size: 4vw;
}

.app-container b {
  color: #d1ac00;
}

.app-container img {
  width: 100%;
}

.app-container .banner {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 85%,
    transparent 100%
  );
}

.app-container a {
  text-decoration: none;
  color: #d1ac00;
}

.app-container .img-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: black;
  width: 100%;
}

.app-container .img-row .img-container {
  width: 50%;
}

.app-container .img-row .img-container img {
  width: 75%;
}

section {
  background-color: white;
}

.app-container .sponsor-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.app-container .sponsor-img-container {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.app-container .sponsor-img-container img,
.app-container .sponsor-img-container svg {
  width: 50%;
}

@media screen and (min-width: 30rem) {
  .app-container h1 {
    font-size: 3.75vw;
  }

  .app-container h2 {
    font-size: 3.5vw;
  }

  .app-container p {
    font-size: 3vw;
    max-width: 50%;
  }
}

@media screen and (min-width: 55rem) {
  .app-container h1 {
    font-size: 3vw;
  }

  .app-container h2 {
    font-size: 2vw;
  }

  .app-container p {
    font-size: 1.5vw;
    max-width: 50%;
  }

  .app-container img {
    max-height: 100%;
  }

  .app-container .img-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .app-container .img-row .img-container {
    width: 25%;
  }

  .app-container .img-row .img-container img {
    width: 60%;
  }

  .app-container .sponsor-img-container {
    width: 50%;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .app-container .sponsor-img-container img {
    width: 50%;
  }

  .app-container .sponsor-img-container svg {
    width: 50%;
  }
}
