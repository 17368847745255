@font-face {
  font-family: "Kollektif";
  src: url("./assets/fonts/Kollektif.ttf");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Kollektif";
}
